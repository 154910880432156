<template>
  <nav class="app-navbar__tabs" ref="tabs">
    <ul class="app-navbar__tabs__list">
      <li
        v-for="(navbarItem, index) in navbarItems"
        :key="index"
        class="app-navbar__tabs__item"
        :class="{
          active: isActive(navbarItem),
          'app-animation--loading': loadingIndex === index,
        }"
      >
        <NuxtLink
          @click="handleClick(index, navbarItem, $event)"
          :to="{ path: localePath(navbarItem.path), query: navbarItem.query }"
          class="app-navbar__tabs__link"
        >
          {{ $t(navbarItem.name) }}
        </NuxtLink>
      </li>
    </ul>
    <NavbarSearch v-if="currentPage != '/sites'" name="term" />
  </nav>
</template>

<script lang="ts" setup>
const route = useRoute()
const localePath = useLocalePath()
const { userProfile } = useKeycloak()
const loadingIndex = ref<number | null>(null)
const {$features} = useNuxtApp()


const currentPage = computed(() => {
  if (route.path.includes('tags')) return '/tags'
  if (route.path.includes('collection')) return '/collection'
  if (route.path.includes('training/gallery')) return '/training/gallery'
  if (route.path.includes('training')) return '/training'
  if (route.path.includes('sites')) return '/sites'
  if (route.path.includes('invitations')) return '/invitations'
  else return '/'
})

const navbarItems = computed(() => {
  if (route.path.includes('sites')) {
    if (userProfile.IsAdmin) {
      return [
        { name: 'My sites', path: '/sites', query: { view: 'private' } },
        { name: 'Public sites', path: '/sites', query: { view: 'public' } },
        { name: 'Admin', path: '/sites', query: { view: 'admin' } },
      ]
    }
    return [
      { name: 'My sites', path: '/sites', query: { view: 'private' } },
      { name: 'Public sites', path: '/sites', query: { view: 'public' } },
    ]
  }
  if (route.path.includes('training')) {
    return [
      { name: 'Dashboard', path: '/training' },
      { name: 'Gallery', path: '/training/gallery' },
    ]
  }

  let routes = [
    { name: 'Home', path: '/' },
    { name: 'Collections', path: '/collection' },
    { name: 'Tags', path: '/tags' },
  ]
  if ($features.invitations) {
    routes.push({ name: 'Invitations', path: '/invitations' })
  }

  return routes
})

watch(
  () => route.fullPath,
  () => {
    setTimeout(() => {
      loadingIndex.value = null
    }, 100)
  }
)

function isActive(item: any): boolean {
  if (currentPage.value === '/sites') {
    if (route.query.view === undefined) {
      return item.query.view === 'private'
    } else {
      return item.query.view === route.query.view
    }
  } else {
    return item.path === currentPage.value
  }
}

function handleClick(index: number, navbarItem: any, event): void {
    // do not set loading index if the item is already active
    // if the item is active, the page will not reload, 
    // thus we should not show the loading animation
  if(navbarItem && !isActive(navbarItem) && !(event.ctrlKey || event.metaKey)) {
    loadingIndex.value = index
  }
}
</script>

<style></style>
