<template>
  <div class="app-layout" :class="currentTemplate">
    <!-- Slideover -->
    <LazyTailwindComponentsSlideOver
      :maxWidth="slideOver?.maxWidth"
      :minWidth="slideOver?.minWidth"
      :title="slideOver?.name"
      :overlayModal="slideOver?.overlayModal || false"
      btn-type="secondary"
      ref="drawer"
      :class="isEditor && 'w-full h-full'"
    >
      <template v-slot:slide-content>
        <component
          :key="slideOverKey"
          :is="slideOver?.content.component"
          v-bind="slideOver?.content.props"
          v-on="slideOver?.content.events || {}"
      /></template>
      <template v-slot:page-content>
        <LayoutHeader v-if="!isEditor" />
        <main class="app-layout__main" :class="isEditor && 'w-full h-full'">
          <slot />
        </main>
      </template>
    </LazyTailwindComponentsSlideOver>

    <LazyBaseModal
      v-for="(modal, index) in modals"
      :key="index"
      :layoutChange="modal.layoutChange"
      :isFullscreen="modal.isFullscreen"
      :isMedium="modal.isMedium"
      :isSmall="modal.isSmall"
      :noPadding="modal.noPadding"
      :maxFit="modal.maxFit"
      :customheader="modal.customHeader"
      :modalInfo="modal.modalInfo"
      :open="modal.open"
      @close="closeModal"
    />

    <!-- Notification -->
    <transition-group
      tag="div"
      class="fixed flex flex-col items-end z-[10000] top-4 right-4"
      :name="`notification`"
    >
      <div
        class="notification-item"
        v-for="(notification, index) in notifications"
        :key="notification.key"
      >
        <LazyBaseNotification
          :key="notification.key"
          :message="notification.message"
          :details="notification.details"
          :position="notification.position"
          :action="notification.action"
          :actionName="notification.actionName"
          :type="notification.type"
          :duration="notification.duration"
          :indefinite="notification.indefinite"
          :canCloseOnHover="notification.closeable"
          :open="notification.open"
          @close="closeNotification(index)"
        ></LazyBaseNotification>
      </div>
    </transition-group>
  </div>
</template>

<script setup lang="ts">

// Modal
interface Modal {
  isFullscreen?: boolean
  isMedium?: boolean
  isSmall?: boolean
  noPadding?: boolean
  maxFit?: boolean
  customHeader?: string
  modalInfo?: Object
  open?: boolean
  layoutChange?: boolean
}

let slideOver = ref()

const slideOverKey = ref(0)

const route = useRoute()
const { $bus } = useNuxtApp()
const configStore = useConfig()
const settings = ref<any>(null)
const { setLocale } = useI18n()

onMounted(() => {
  const config = useRuntimeConfig()
  loadLocale()
  $bus.on('open-modal', createModal)
  $bus.on('close-modal', closeModal)
  $bus.on('modal-close-all', closeAllModals)
  $bus.on('slideOver', setSlideOver)
  $bus.on('notification', createNotification)
})

onUnmounted(() => {
  $bus.off('open-modal', createModal)
  $bus.off('close-modal', closeModal)
  $bus.off('modal-close-all', closeAllModals)
  $bus.off('slideOver', setSlideOver)
  $bus.off('notification', createNotification)
})

const currentTemplate = computed(() => {
  if (route.query?.layout === 'training') return 'app-layout--training'
  if (route.path.includes('training')) return 'app-layout--training'
  if (route.path.includes('sites')) return 'app-layout--sites'
  if (isEditor) return 'app-layout--edit'
})

const isEditor = computed(() => {
  return route.path.includes('edit') || route.path.includes('share')
})

const modals: Ref<Array<Modal>> = ref([])

async function loadLocale() {
  if (!configStore.config) {
    await configStore.fetchConfig()
  }
  settings.value = configStore.config
  if (settings.value.locale) {
    setLocale(settings.value.locale)
  }
}

function setSlideOver(data) {
  if (data.remount) {
    slideOverKey.value++
  }
  slideOver.value = {
    overlayModal: data.overlayModal,
    name: data.slideOver,
    spec: data.spec,
    content: markRaw(data.slideContent),
    props: data.slideContent.props,
    maxWidth: data.maxWidth,
    minWidth: data.minWidth,
  }
}

function createModal(data) {
  const newModal: Modal = {
    isFullscreen: data.props?.isFullscreen,
    isMedium: data.props?.isMedium,
    isSmall: data.props?.isSmall,
    noPadding: data.props?.noPadding,
    maxFit: data.props?.maxFit,
    customHeader: data.props?.customHeader,
    modalInfo: markRaw(data.modal),
    open: false,
    layoutChange: data.props?.layoutChange,
  }
  modals.value.push(newModal)
  newModal.open = true
}

function closeAllModals() {
  modals.value = []
}

function closeModal() {
  const oldModal = modals.value[modals.value.length - 1]
  oldModal.open = false
  setTimeout(() => {
    const index = modals.value.indexOf(oldModal)
    if (index !== -1) {
      modals.value.splice(index, 1)
    }
  }, 200)
}

// Notification
interface Notification {
  key: string
  message: string
  details?: string
  position?: string
  type?: string
  duration?: number
  indefinite?: boolean
  closeable?: boolean
  actionName?: string
  action?: () => void
  open?: boolean
}

const notifications: Ref<Array<Notification>> = ref([])

const createNotification = (data: Notification) => {
  let newKey = generateRandomKey()
  const notification: Notification = {
    key: newKey,
    message: data?.message || '',
    details: data?.details || '',
    position: data?.position || 'right',
    type: data?.type || 'info',
    duration: data?.duration || 5000,
    indefinite: data?.indefinite || false,
    closeable: data?.closeable || true,
    actionName: data?.actionName || '',
    action: data?.action || (() => null),
    open: false,
  }
  notifications.value = [notification, ...notifications.value]
}

const closeNotification = (index: number) => {
  notifications.value = notifications.value.filter((n, i) => i !== index)
}

const generateRandomKey = () => {
  const characters = 'ABCDEF'
  let key = ''
  for (let i = 0; i < 10; i++) {
    key += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return key
}
</script>

<style lang="scss"></style>

<style>
.notification-item {
  @apply mb-3 inline-block;
}

.notification-leave-active {
  @apply absolute;
}

.notification-move {
  transition: transform 1s;
}
</style>
