<template>
  <header class="app-layout__header">
    <NavbarMain />
    <NavbarSecondary />
  </header>
</template>

<script lang="ts" setup></script>

<style></style>
